<template>
  <div class="home1111">
    <!-- size="small" -->
    <a-table :columns="columns" :row-selection="rowSelection" rowKey="id" :pagination="pagination"
      @change="handleTableChange" :data-source="data" bordered>
      <template slot="companyName" slot-scope="text, record">
        <a-popover  trigger="hover">
          <template #content>
            <div style="width: 350px;">{{text}}</div>
          </template>
          <div class="ellipsis_text">{{text}}</div>
        </a-popover>
      </template>
      <template slot="createAt" slot-scope="text, record">
        {{ formatTime(text) }}
      </template>
      <template slot="title" slot-scope="currentPageData">
        <a-date-picker style="width: 200px" placeholder="开始日期" v-model="searchInfo.beginDate"
          valueFormat="YYYY-MM-DD" />
        &nbsp;<a-time-picker style="width: 200px" placeholder="开始时间" v-model="searchInfo.beginTime"
          valueFormat="HH:mm:ss" />
        &nbsp;<a-date-picker style="width: 200px" placeholder="结束日期" v-model="searchInfo.endDate"
          valueFormat="YYYY-MM-DD" />
        &nbsp;<a-time-picker style="width: 200px" placeholder="结束时间" v-model="searchInfo.endTime"
          valueFormat="HH:mm:ss" />
        &nbsp;<a-button type="primary" icon="search" @click="loadTrailDatas(1)" :loading="searchLoading">搜索</a-button>
        <div class="rightTools"> <a-button @click="startExport({})">导出报表</a-button> &nbsp;
        </div>
      </template>
      <template slot="footer" slot-scope="currentPageData">
        <div style="text-align:left;width:100%;height:100%">
          <a-space>
            <span>共: {{ pagination.total }} 条记录</span>
            每页:
            <a-select v-model="pageSize" style="width: 70px" @change="pageSizeChange">
              <a-select-option value="5">
                5
              </a-select-option>
              <a-select-option value="10">
                10
              </a-select-option>
              <a-select-option value="20">
                20
              </a-select-option>
              <a-select-option value="50">
                50
              </a-select-option>
              <a-select-option value="100">
                100
              </a-select-option>
              <a-select-option value="200">
                200
              </a-select-option>
              <a-select-option value="500">
                500
              </a-select-option>
            </a-select>
            <span>条记录,已选择[ <span style="color:red;">{{ selectRows.length }}</span> ]</span>
            <!-- <a-button @click="buildReport" :loading="reportBuilding" :disabled="reportBuilding">生成选择数据的报表</a-button> -->
            <!-- <a v-if="reportFile" target="_blank" :href="reportFile.src" >点击下载: {{reportFile.title}}({{reportFile.contentSize/1000}}Kb)</a> -->
          </a-space>
          <!-- <a style="float:right;display:block;width:120px;height:32px;" target="_blank" href="./template.xlsx">下载导入模板文件</a> -->
        </div>
      </template>
    </a-table>

  </div>
</template>

<script>
import moment from 'moment';

let that = null;
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    that.selectRows = selectedRows;
  },
  onSelect: (record, selected, selectedRows) => {
    that.selectRows = selectedRows;
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    that.selectRows = selectedRows;
  },
};

const fData = function (date, fmt) {
  date = new Date(date);
  if (typeof (fmt) === "undefined") {
    fmt = "yyyy-MM-dd";
  }
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  let o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'H+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  }
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      let str = o[k] + ''
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : ('00' + str).substr(str.length));
    }
  }
  return fmt
}

const columns = [
  {
    title: '公司名称',
    dataIndex: 'companyName',
    width: 150,
    scopedSlots: { customRender: 'companyName' }

  },
  // {
  //   title: '客户名称',
  //   width:120,
  //   dataIndex: 'name',
  // },
  {
    title: '手机号码',
    width: 120,
    dataIndex: 'mobile'
  },
  // {
  //   title: '电话号码',
  //   width:120,
  //   dataIndex: 'phone'
  // },{
  //   title: '邮箱',
  //   width:120,
  //   dataIndex: 'email'
  // },
  {
    title: '国家',
    width: 100,
    dataIndex: 'country'
  },
  // {
  //   title: '城市',
  //   width:100,
  //   dataIndex: 'city'
  // },{
  //   title: '联系地址',
  //   width:180,
  //   dataIndex: 'address',
  //   ellipsis:false,
  // },{
  //   title: '邮政编码',
  //   width:100,
  //   dataIndex: 'zip'
  // },
  {
    title: '创建时间',
    width: 180,
    dataIndex: 'createAt',

    scopedSlots: { customRender: 'createAt' }
  }
];
import { message } from 'ant-design-vue';

export default {
  data() {
    return {
      moment,
      rowSelection,
      reportBuilding: false,
      reportFile: null,
      selectRows: [],
      searchLoading: false,
      editSpin: false,
      pagination: {},
      pageSize: 5,
      labelCol: { span: 9 },
      wrapperCol: { span: 15 },
      stepDefines: [],
      stepDefineMap: {},
      data: [],
      columns,
      visible: false,
      addStepVisible: false,
      newStep: { index: null, stepTitle: "", stepDesc: "", stepAt: null },
      newStepDate: moment(),
      reportSelect: [],

      searchInfo: {
        beginDate: "",
        beginTime: "00:00:00",
        endDate: "",
        endTime: "23:59:59",

      },

    };
  },
  mounted() {
    that = this;
    this.$ready(() => {
      this.loadTrailDatas(1);
    });

  },
  methods: {
    formatDate: fData,

    formatTime(date) {
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
    },
    pageSizeChange(value) {
      this.pageSize = value;
      this.pagination.current = 1;
      this.loadTrailDatas(1);
    },

    changeHandler(value, mode) {
      if (this.newStep) {
        this.newStep.stepAt = value.valueOf();
        this.newStep.date = value;
      }
      console.info(this.newStep.date.valueOf(), value.valueOf());
    },



    handleTableChange(pagination, filters, sorter) {
      this.loadTrailDatas(pagination.current);
    },


    sortStepsByTime(arr) {
      return arr.sort((a, b) => {
        return b.stepAt - a.stepAt;
      });
    },

    stepDefineSelected(v) {
      let data = this.stepDefineMap[v];
      if (data) {
        this.newStep.stepTitle = data.stepTitle;
        this.newStep.stepDesc = data.stepDesc;
      }
    },

    perAddStep() {
      this.newStep = { index: null, stepTitle: "", stepDesc: "", stepAt: null };
      this.newStepDate = moment();
    },




    startExport() {
      if (!this.searchInfo.beginDate || !this.searchInfo.endDate) {
        this.$message.error('请选择开始日期和结束日期');
        return;
      }
      const params = this.getParams({});
      const hide = message.loading('提交中..', 0);
      this.$axios.get("/customerHint/report/export", {
        params
      }).then(resp => {
        hide()
        if (resp.data && resp.data["code"] == 200) {
          // message.success('提交失败');
          this.$notification["success"]({
            message: '提交成功',
            description:
              '可到报表管理界面进行下载.',
          });
        } else {
          this.$notification.error({
            message: '提交失败',
            description:
              resp.data.msg,
          });
        }
      }).catch(err => {
        hide()
        this.$notification.error({
          message: '提交失败',
        });
      })
    },

    getParams(data) {
      const params = Object.assign({}, data);
      if (this.searchInfo.beginDate) {
        params.beginDate = this.searchInfo.beginDate;
        if (this.searchInfo.beginTime) {
          params.beginDate += (" " + this.searchInfo.beginTime);
        } else {
          params.beginDate += (" 00:00:00");
        }
        params.beginDate = moment(params.beginDate).valueOf();
      }
      if (this.searchInfo.endDate) {
        params.endDate = this.searchInfo.endDate;
        if (this.searchInfo.endTime) {
          params.endDate += (" " + this.searchInfo.endTime);
        } else {
          params.endDate += (" 23:59:59");
        }
        params.endDate = moment(params.endDate).valueOf();
      }
      console.log(this.searchInfo, params)
      return params
    },

    loadTrailDatas(page) {
      this.searchLoading = true;
      ///trail/page
      const params = this.getParams({
        pageNo: page,
        pageSize: this.pageSize,
      });
      this.$axios.get("/customerHint/page", {
        params
      }).then(resp => {
        this.searchLoading = false;
        if (resp.data && resp.data["code"] == 200) {
     
          this.data = resp.data.data.records;
          // let paginationInfo = {...this.pagination}
          // console.info(paginationInfo, this.pagination);

          // paginationInfo.current = resp.data.data.current;
          // paginationInfo.total = resp.data.data.total;
          // paginationInfo.pageSize = resp.data.data.pageSize;

          // this.pagination = paginationInfo;

          const pagination = { ...this.pagination };
          pagination.current = parseInt(resp.data.data.current + "");
          pagination.pageSize = this.pageSize;
          pagination.total = parseInt(resp.data.data.total + "");
          this.pagination = pagination;

        } else {
          this.$notification.error({
            message: '数据获取失败',
            description:
              resp.data.msg,
          });
        }
      })
    }
  }
};
</script>

<style scoped>
.ant-table-title {
  text-align: left !important;
}

.rightTools {
  float: right;
}

.ant-form-item {
  margin-bottom: 6px !important;
}
.ellipsis_text {
  display: -webkit-box;          /* 关键属性：启用弹性盒子模型 */
  -webkit-box-orient: vertical;   /* 文本排列方向：垂直 */
  -webkit-line-clamp: 2;         /* 限制显示行数 */
  overflow: hidden;              /* 溢出隐藏 */
  text-overflow: ellipsis;       /* 显示省略号 */
  word-break: break-all;         /* 允许单词内断行（可选） */
}
</style>
